
<template>
  <div class="box">
    <div class="top">
      <div class="org">
        <span>{{buildingName}}</span><span></span>
      </div>

<!--      <div  class="boxss" style="background-color: white;">-->
<!--      <div class="content">-->
<!--        <div class="orgname"><span class="span1">{{buildingName}}</span><span class="span2">{{divideUnit}}单元{{divideHouse}}房</span></div>-->
<!--        <div class="user" v-if="gridManger.length>0&&gridManger[0]">-->
<!--          <span >网格长:&nbsp;<span class="phone">{{gridManger[0].name}}&nbsp;{{gridManger[0].mobile}}</span></span> <img src="@/assets/img/mobile.png" alt="" class="tel" @click.stop="getRealMobile(gridManger[0].id)"/>-->
<!--        </div>-->
<!--        <div v-else class="user"><span>暂无网格长信息</span></div>-->
<!--        <div class="user" v-if="buildingAssistant.length>0">-->
<!--          <span>协管员:&nbsp;<span class="phone">{{buildingAssistant[0].name}}&nbsp;{{buildingAssistant[0].mobile}}</span></span> <img src="@/assets/img/mobile.png" alt="" class="tel" @click.stop="getRealMobile(buildingAssistant[0].id)"/>-->
<!--        </div>-->
<!--        <div v-else class="user"><span >暂无协管员信息</span></div>-->
<!--      </div>-->
<!--      <van-divider />-->
<!--      <div class="button-box">-->
<!--        <button class="btn" @click="goRepair(userId)" v-if="userId">报修</button>-->
<!--        <button class="btn"   @click="goProblem(userId)" v-if="userId">问题</button>-->
<!--&lt;!&ndash;        <button class="btn"  @click="checkInfos(buildingAssistant[0])" v-if="buildingAssistant[0]">巡查</button>&ndash;&gt;-->
<!--      </div>-->
<!--      </div>-->
        </div>
    <div class="user-info">
      <div class="user-info-title">{{ managerNum }}员信息</div>
      <div style="display: flex; justify-content: space-between; flex-wrap: wrap">
        <div class="user-info-item" v-for="item in userInfoList">
          <img :src="item.avatar" alt="">
          <div>
            <div class="post">{{ item.workName }}</div>
            <div class="name">{{ item.name ||''}}</div>
            <div class="mobile">{{ getMobile(item.mobile) }}</div>
        </div>
      </div>

      </div>
    </div>

    <div class="box-list">
      <div class="title"><span>房屋信息</span><span class="title-end">房屋总数：<span class="end-num">{{totalHouse}}</span><span class="end-DW">（套）</span></span></div>
      <div class="numList">
        <div class="ega" v-for="item in HouseTypeList"  @click="goHouse(item.value)">
          <span class="number" :style="{color:(item.value==8||item.value==7)?'red':'black'}">{{item.number}}</span>
          <span class="type" :style="{color:(item.value==8||item.value==7)?'red':'black'}">{{item.label}}</span>
        </div>

      </div>

    </div>
    <div class="box-list">
      <div class="title"><span>人员信息</span><span class="title-end">人员总数：<span class="end-num">{{userTotal}}</span><span class="end-DW">（人）</span></span></div>
      <div class="numList">
        <div class="ega" v-for="item in userTypeList"  @click="goRes(parseInt(item.value))">
          <span class="number" :style="{color:(item.value==20||item.value==21)?'red':'black'}">{{item.count}}</span>
          <span class="type" :style="{color:(item.value==20||item.value==21)?'red':'black'}" >{{item.label}}</span>
        </div>
      </div>

    </div>
    <div class="box-list" v-if="label.length>0">
      <div class="title"><span>标签信息</span></div>
      <div class="numList">
        <div class="ega" v-for="item in label" @click="goResLabel(item.value)">
          <span class="number">{{item.num}}</span>
          <span class="type">{{item.label}}</span>
        </div>
      </div>

    </div>
    <div class="box-list" v-if="shop.length>0">
      <div class="title"><span>店铺信息</span></div>
      <div class="numList">
        <div class="ega" v-for="item in shop">
          <span class="number">{{item.count}}</span>
          <span class="type">{{item.type}}</span>
        </div>
      </div>

    </div>
    <div class="box-list" v-if="event.length>0">
      <div class="title"><span>近一个月事件信息</span></div>
      <div class="numList">
        <div class="ega" v-for="item in event" @click="goApp(text)">
          <span class="number">{{item.count}}</span>
          <span class="type">{{item.text}}</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {getRealMobile} from "@/utils/common";
import Vue from "vue";
import {getImageStream} from "@/utils";

export default {
  data(){
    return{
      AppUrl:'https://h5.app.hzuht.com/app',
      type:2,
      id: '',
      userTotal:0,
      totalHouse:0,
      OrgName:'',
      managerNum:'',
      gridName:'',
      miniGridName:'',
      buildingName:'',
      event:[],//近一个月事件
      house:[],//房屋信息
      label:[],//标签信息
      person:[],//人员信息
      shop:[],//店铺信息
      buildingAssistant:[],//协管员
      gridManger:[],//网格长
      openid:'',
      unionid:'',
      buildingId:'',
      userTypeList:[],
      HouseTypeList:[],
      userId:'',
      orgId:'',
      divideUnit:'',//单元
      divideHouse:'',
      assistId:'' ,//协管员id
      // isVisitOpen:false,//是否开启访客功能
      repairAppInfo: {},//报修
      problemAppInfo:{},//问题
      checkInfo:{},//巡查
      userInfoList: [],
      url:'https://sgg.hzxh.gov.cn/police-api',
      // url:'http://192.168.0.77:8091',
    }
  },
  created() {
    // console.log(this.$route.query.orgId)
    // Vue.prototype.$orgId=this.$route.query.buildingId
    // Vue.prototype.$globalData.userInfo.orgId = this.$route.query.orgId
    // console.log(this.$globalData.userInfo.orgId)
    this.$orgId=this.$route.query.orgId
    this.$globalData.userInfo.assistId=this.$route.query.assistId
    this.$globalData.userInfo.userId=this.$route.query.userId
    this.$globalData.userInfo.orgId=this.$route.query.orgId
    console.log(this.$route.query.buildingId)
    this.assistId=this.$route.query.assistId
    console.log(this.assistId)
    this.userId=this.$route.query.userId
    this.orgId=this.$route.query.orgId
    this.openid = this.$route.query.openid
    this.unionid = this.$route.query.unionid
    this.buildingId=this.$route.query.buildingId
    this.id=this.$route.query.buildingId
    // this.getUserdict()
    this.getHousedict()
    this.getAppInfo()
    this.getManager()
  },
  mounted() {
    this.getHouse()
  },
  methods:{
    getManager() {
      this.$httpApp({
        url: this.$httpApp.adornUrl(`/building/manager/info/getManager/${this.id}`),
        method: 'get',
        params: this.$httpApp.adornParams({
          type: this.type
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          let tmp = data.managers
          data.managers.map((item, index) => {
            tmp[index].avatar = getImageStream(item.avatar)
          })
          this.userInfoList = tmp
          this.managerNum = this.numChange(data.managers.length)
        }
      })
    },
    numChange(i) {
      let arry = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
      if (i <= 10) {
        return arry[i];
      }
      if (i % 10 == 0 && i < 100) {
        return arry[Math.floor(i / 10)] + "十";
      }
      if (i > 10 && i < 20) {
        return "十" + this.numChange(i - 10 * Math.floor(i / 10));
      }
      if (i > 20 && i < 100 && i % 10 != 0) {
        return arry[Math.floor(i / 10)] + "十" + numChange(i - 10 * Math.floor(i / 10));
      }
    },
    goApp(text){
      if(text=='未处理问题'){
        this.$router.push({
          path: '/appSSO',
          query: {
            solveStatus:10,
            buildingId:this.buildingId,
            SM:'saoma',
            orgId:this.$orgId,
            appId: this.problemAppInfo.appId,
            appCode: this.problemAppInfo.appCode,
            appName: this.problemAppInfo.appName,
            url: this.problemAppInfo.pageUrlH5
          }
        })
      }
      if(text=='未处理报修'){
        // let url = this.visitAppInfo.pageUrlH5.split('=')[0] + '=repair-add'
        this.$router.push({
          path: '/appSSO',
          query: {
            solveStatus:10,
            SM:'saoma',
            userId:this.userId,
            orgId:this.$orgId,
            buildingId:this.buildingId,
            appId: this.repairAppInfo.appId,
            appCode: this.repairAppInfo.appCode,
            appName: this.repairAppInfo.appName,
            url: this.repairAppInfo.pageUrlH5
          }
        })
      }

    },
    getMobile(value) {
      let result = [];
      for (let i = 0; i < value.length; i++) {
        if (i == 3 || i == 7) {
          result.push(" " + value.charAt(i));
        } else {
          result.push(value.charAt(i));
        }
      }
      return result.join('')
    },
    getAppInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/geMyAppInfoByCode'),
        method: 'GET',
        params: this.$http.adornParams({
          codes: 'sjgl_bxjl,sjgl_wtjl,sjgl_xcjl',
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          data.list.forEach(item => {
            if(item.appCode === 'sjgl_bxjl') {
              // this.isRepairsOpen = true
              this.repairAppInfo = item
            }
            if(item.appCode === 'sjgl_wtjl') {
              // this.isProblemOpen = true
              this.problemAppInfo = item
            }
            if(item.appCode === 'sjgl_xcjl') {
              // this.isVisitOpen = true
              this.checkInfo = item
            }
          })
          if(data.list && data.list.length) {
            let appId = data.list[0].appId
            // getAppToken(appId)
            // this.$http({
            //   url: this.$http.adornUrl('/wxapp/application/appToken'),
            //   method: 'get',
            //   params: this.$http.adornParams({
            //     appId,
            //   })
            // }).then(({data}) => {
            //   if(data && data.code === 0) {
            //     this.$httpApp({
            //       url: this.$httpApp.adornUrl('/auth/getLoginUser'),
            //       method: 'GET',
            //       params: this.$httpApp.adornParams({
            //         appId,
            //         appToken: data.appToken
            //       })
            //     }).then(({data}) => {
            //       if(data && data.code === 0) {
            //         sessionStorage.setItem('appToken', data.appToken)
            //       }
            //     })
            //   }
            // })
          }
        }
      })
    },
    //跳转报修
    goRepair(item) {
      let url = this.repairAppInfo.pageUrlH5.split('=')[0] + '=repair-add'
      this.$router.push({
        path: '/appSSO',
        query: {
          SM:'saoma',
          userId:item.id,
          orgId:this.$orgId,
          houseId: item.id+ '',
          appId: this.repairAppInfo.appId,
          appCode: this.repairAppInfo.appCode,
          appName: this.repairAppInfo.appName,
          url: 'https://h5.app.hzuht.com/app/repair-add'
        }
      })
    },
    //跳转问题
    goProblem(item) {
      let url = this.problemAppInfo.pageUrlH5.split('=')[0] + '=problem-add'
      this.$router.push({
        path: '/appSSO',
        query: {
          buildingId:this.buildingId,
          SM:'saoma',
          orgId:this.$orgId,
          houseId: item.id + '',
          appId: this.problemAppInfo.appId,
          appCode: this.problemAppInfo.appCode,
          appName: this.problemAppInfo.appName,
          url: url
          // url: this.problemAppInfo.pageUrlH5,
        }
      })
    },
    //跳转巡查
    checkInfos(item){
      let url = this.checkInfo.pageUrlH5.split('=')[0] + '=patrol-add'
      this.$router.push({
        path: '/appSSO',
        query: {
          placeId: item.id||'',
          placeType: 2,
          SM:'saoma',
          userId:item.id||'',
          orgId:this.$orgId,
          houseId:item.id + '',
          appId: this.checkInfo.appId,
          appCode: this.checkInfo.appCode,
          appName: this.checkInfo.appName,
          url: url,
        }
      })
    },
    //获取人口性质
    getUserdict(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual'),
        method: 'GET',
        params: this.$http.adornParams({
          code: 'registryType'
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.userTypeList = data.virtualDicts
          this.userTypeList.forEach((item)=>{
            item.number=0
          })
          this.userTypeList.push({value:20,label:'无证件人员',number:0})
          this.userTypeList.push({value:21,label:'身份证有误',number:0})
        }
      })
    },
    //获取房屋性质
    getHousedict(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual'),
        method: 'GET',
        params: this.$http.adornParams({
          code: 'useType'
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.HouseTypeList = data.virtualDicts
          this.HouseTypeList.forEach((item)=>{
            item.number=0
          })
          this.HouseTypeList.push({value:7,label:'群租超限',number:0},{value:8,label:'未签约',number:0})
        }
      })
    },
    //获取信息
    getHouse(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/statistics/count'),
        method: 'GET',
        params: this.$http.adornParams({
          buildingId: this.buildingId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.totalHouse=data.statistics.totalHouse
          this.userTotal=data.statistics.userTotal
          this.divideUnit=data.statistics.buildingInfo.divideUnit==0?'不分':'分'
          this.divideHouse=data.statistics.buildingInfo.divideHouse==0?'不分':'分'
          this.buildingName=data.statistics.buildingInfo.subareaName +data.statistics.buildingInfo.name+'幢'
          this.OrgName=data.statistics.buildingInfo.communityName
          this.miniGridName=data.statistics.buildingInfo.miniGridName
          this.gridName=data.statistics.buildingInfo.gridName
          if(data.statistics.buildingAssistant.length>0) {
            this.buildingAssistant.push(data.statistics.buildingAssistant[0])
          }
          if(data.statistics.gridManger.length>0){
            this.gridManger.push(data.statistics.gridManger[0])
          }
          this.event=data.statistics.event
          this.shop=data.statistics.shop
          this.label=data.statistics.label
          data.statistics.house.forEach((val)=>{
            this.HouseTypeList.find((item)=>{
              if(item.value==val.type){
                this.$set(item, 'number', val.count);

              }
            })
          })
        this.userTypeList=data.statistics.person
          this.$forceUpdate();
        }
      })
    },
    getRealMobile(id) {
      getRealMobile(id,function(data) {
        window.location.href = `tel:${data.mobile}`
      })
    },
    //跳转居民详情
    goRes(type){
      if(type!=20&&type!=21){
        this.$router.push({path:'/userRes-index',query:{buildingId:this.buildingId,type:type,Code:'admin',orgId:this.orgId,userId:this.userId,assistId:this.assistId}})
      }

    },
    goResLabel(type){

        this.$router.push({path:'/userRes-index',query:{buildingId:this.buildingId,type:-1,Code:'admin',orgId:this.orgId,userId:this.userId,assistId:this.assistId,label:type}})


    },
    //跳转房屋
    goHouse(type){
      if(type!=8&&type!=7) {
        this.$router.push({
          path: '/house-new',
          query: {
            buildingId: this.buildingId,
            type: type,
            Code: 'admin',
            orgId: this.orgId,
            userId: this.userId,
            assistId: this.assistId
          }
        })
      }

    }
  }
}
</script>

<style scoped lang="scss">
.box{
  font-family: 'PingFangSC-Regular', serif;
  overflow: auto;
  min-height: 100vh;
  width: 100vw;
  background-image: url("~@/assets/img/save/saveCode-bg.png");
  padding-bottom: 24px;
  //background-image: url("~@/assets/img/home-bg.png");
  //background-size: 100% calc(100% + 176px);
  //background-position: 0 -176px;
  //background-repeat: no-repeat;
  .top{
    padding: 24px 0 24px 0;
    width: 750px;
    height: 200px;
    //background: #FFFFFF;
    .button-box{
      margin-top: 22px;
      display: flex;
      justify-content: end;
      gap: 42px;
      .btn {
        //flex: 1;
        width: 200px;
        height: 60px;
        line-height: 60px;
        border: 0;
        font-size: 28px;
        color: #fff;
        font-family: 'PingFangSC-Regular', serif;
        border-radius: 8px;
        background-color: #4581F8;
        text-align: center;
        margin-right: 26px;
        &:last-child {
          margin: 0;
        }
      }
    }
    .van-divider{
      margin: 0;
    }
    .org{
      width: 690px;
      height: 136px;
      margin: 44px auto 0;
      padding: 0 30px 0 30px;
      background-image: url("~@/assets/img/save/saveCode-cube.png");
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span{
        font-weight: 500;
        font-size: 30px;
        color: #4581F8FF;
        line-height: 136px;
        text-align: center;
        font-style: normal;
      }
    }
    .boxss{
      margin-top:20px;
      padding:10px  30px 10px 30px;

    }
    .content{
      height:228px;
      .orgname{
        margin-top: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 52px;
        .span1{
          font-weight: 600;
          font-size: 30px;
          color: #333333;
          line-height: 42px;
          text-align: left;
          font-style: normal;
        }
        .span2{
          color:#4E7CF5;
          font-size: 30px;
          padding:8px 20px 8px 20px;
          height: 52px;
          background: rgba(78,124,245,0.12);
          border-radius: 8px;
        }
      }
      .user{
        display: flex;
        justify-content: space-between;
        margin-top:24px;
        span{
          font-family: 'PingFangSC-Regular', serif;
          font-weight: 400;
          font-size: 28px;
          color: #666666;
          line-height: 40px;
          text-align: center;
          font-style: normal;
          .phone{
            font-family: 'PingFang Bold', serif;
            font-weight: 500;
            color: #333333;
          }
        }
        img{
          width: 40px;
          height: 40px;
        }
      }
    }

  }
  .box-list{
    margin-top: 24px;
    background-color: white;
    .title{
      //height: 96px;
      display: flex;
      justify-content: space-between;
      padding: 24px 12px  0 30px;
      &:first-child{
        font-family: 'PingFangSC-Regular', serif;
        font-weight: 600;
        font-size: 34px;
        color: #333333;
        line-height: 48px;
        font-style: normal;
      }
      .title-end{
        font-family: 'PingFangSC-Regular', serif;
        font-weight: 400;
        font-size: 28px;
        color: #666666;
        line-height: 40px;
        font-style: normal;
        .end-num{
          font-family: 'PingFangSC-Regular', serif;
          color: #333333;
        }
        .end-DW{
          font-size: 24px;
          color: #666666;
        }
      }
    }
    .numList{
      padding:20px 30px 20px 30px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap:40px 36px;
      .ega {
        display: flex;
        flex-direction: column;
        //gap:4px;
        width: 140px;
        overflow: hidden;
        text-align: center;
        //white-space: nowrap;
        //text-overflow: ellipsis;
        //-o-text-overflow:ellipsis;
        .number {
          //white-space: nowrap;
          //overflow: hidden;
          //text-overflow:ellipsis;
          font-weight: bold;
          font-size: 44px;
          color: #333333;
          line-height: 56px;
          text-align: center;
          font-style: normal;
        }
        .type {
          //white-space: nowrap;
          //overflow: hidden;
          //text-overflow:ellipsis;
          font-weight: 400;
          font-size: 28px;
          color: #666666;
          line-height: 40px;
          text-align: center;
          font-style: normal;
        }
      }
    }
  }
}
.user-info {
  width: 750px;
  padding: 0 30px;
  background: #fff;
  margin-top: 24px;
  border-radius: 0px 0px 20px 20px;

  .user-info-title {
    height: 88px;
    color: #333;
    font-size: 36px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .user-info-item {
    width: 336px;
    height: 170px;
    background: #F8F9FA;
    display: flex;
    padding: 26px 18px;
    margin-bottom: 24px;

    img {
      width: 120px;
      height: 120px;
      margin-right: 12px;
    }

    .post {
      font-size: 26px;
      color: #333;
      line-height: 36px;
      margin-top: 4px;
    }

    .name {
      font-size: 26px;
      color: #333;
      line-height: 36px;
      margin-top: 4px;
    }

    .mobile {
      font-size: 24px;
      color: #666;
      line-height: 34px;
      margin-top: 4px;
    }
  }
}
</style>
